import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pocket-globe-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pocket-globe-app",
        "aria-label": "pocket globe app permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Pocket Globe App`}</h1>
    <p>{`This app let's you explore all the counties of the world by clicking on them on
the draggable and zoomable globe.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/main.gif",
        "alt": "app layout"
      }}></img></p>
    <h2 {...{
      "id": "country-details-through-rest-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#country-details-through-rest-api",
        "aria-label": "country details through rest api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Country details through REST API`}</h2>
    <p>{`To fetch information about each country I'm using the following REST API
endpoints:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://restcountries.eu/"
        }}>{`REST Countries API`}</a>{` - to get information such as
country's capital, currency, etc.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://wikimedia.org/api/rest_v1/"
        }}>{`Wikimedia REST API `}</a>{` - to get a short
paragraph about a country`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/country-info.png",
        "alt": "country details"
      }}></img></p>
    <h2 {...{
      "id": "amazing-photos-from-unsplash-json-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#amazing-photos-from-unsplash-json-api",
        "aria-label": "amazing photos from unsplash json api permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Amazing photos from Unsplash JSON API`}</h2>
    <p>{`All the photos used in the app come from
`}<a parentName="p" {...{
        "href": "https://unsplash.com/developers"
      }}>{`Unsplash JSON API`}</a>{` which provides free and
high-resolution photos.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/unsplash.png",
        "alt": "country photos from unsplash"
      }}></img></p>
    <h2 {...{
      "id": "go-to-your-current-location",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#go-to-your-current-location",
        "aria-label": "go to your current location permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Go to your current location`}</h2>
    <p>{`Show the country you're currently in. (You'll have to give the app access to
your location.)`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/location.gif",
        "alt": "show my location"
      }}></img></p>
    <h2 {...{
      "id": "show-random-country",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#show-random-country",
        "aria-label": "show random country permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Show random country`}</h2>
    <p>{`Let the app select a random country for you by clicking on `}<inlineCode parentName="p">{`Show random country`}</inlineCode>{`
widget.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/random.gif",
        "alt": "show random country"
      }}></img></p>
    <h2 {...{
      "id": "autocomplete-form",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#autocomplete-form",
        "aria-label": "autocomplete form permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Autocomplete form`}</h2>
    <p>{`Use autocomplete form to find a country.`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/autocomplete.gif",
        "alt": "autocomplete form"
      }}></img></p>
    <h2 {...{
      "id": "dark-mode",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dark-mode",
        "aria-label": "dark mode permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Dark mode`}</h2>
    <p>{`You can use the app in dark mode if you like!`}</p>
    <p><img parentName="p" {...{
        "src": "https://raw.githubusercontent.com/sitek94/pocket-globe-app/master/screenshots/dark-mode.png",
        "alt": "dark mode"
      }}></img></p>
    <h2 {...{
      "id": "technologies-used",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#technologies-used",
        "aria-label": "technologies used permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Technologies used`}</h2>
    <ul>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`D3 + TopoJSON + World Atlas TopoJSON`}</li>
      <li parentName="ul">{`Material-UI`}</li>
      <li parentName="ul">{`REST Countries API`}</li>
      <li parentName="ul">{`Unsplash API`}</li>
      <li parentName="ul">{`Wikimedia REST API`}</li>
    </ul>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fillRule": "evenodd",
          "clipRule": "evenodd"
        }}><path parentName="svg" {...{
            "d": "M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"
          }}></path></svg></a>{`Accessibility`}</h2>
    <p>{`Keyboard shortcuts`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ctrl ↑`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rotate up`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ctrl ↓`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rotate down`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ctrl ←`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rotate left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ctrl →`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rotate right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`+`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Zoom in`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Zoom out`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`l`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Rotate to selected country`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`r`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Select random country`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`w`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Show/hide widgets`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ctrl /`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Show/hide shortcuts`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      